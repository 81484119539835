import React from "react"
import { Link } from "gatsby"
import { Breadcrumb, Menu, Affix } from "antd"

const Layout = ({ children, work, name }) => {
  let header
  const works = [
    { tag: "youtube", link: "/tag/사윤TV" },
    { tag: "webtoon", link: "/tag/webtoon" },
    { tag: "service", link: "/tag/service" },
    { tag: "class", link: "/tag/class" },
    { tag: "offline", link: "/content/offline" },
  ]
  const menu = (
    <Menu>
      {works.map(work => (
        <Menu.Item key={work.tag}>
          <Link to={work.link} itemProp="url">
            {work.tag.toUpperCase()}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
  header = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">
          <b>SAYUN STUDIO</b>
        </Link>
      </Breadcrumb.Item>
      {work && (
        <Breadcrumb.Item overlay={menu}>{work.toUpperCase()}</Breadcrumb.Item>
      )}
      {name && <Breadcrumb.Item>{name}</Breadcrumb.Item>}
    </Breadcrumb>
  )

  return (
    <>
      <Affix offsetTop={0}>
        <header className="global-header">{header}</header>
      </Affix>
      <div className="global-wrapper">
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> &{" "}
          <a href="https://sayun.studio">SayunStudio</a>
        </footer>
      </div>
    </>
  )
}

export default Layout
